jQuery(document).ready(function($) {

  //Variable to track if a user has clicked a tab
  var BTCSUserClicked = false;

  $('.btcs-tabs-wrapper .tab-links').on('click', function(e) {
    if(BTCSUserClicked == false){
      //If there hasn't been a previous user click...
      e.preventDefault();
      var tabID = $(this).attr('data-tab-id');
      // Remove active state from matching slide and tab nav items
      $(this).siblings('.tab-links').removeClass('active');
      $('.btcs-single-slide-wrapper').removeClass('active');
      // Add active class to slide and tab nav matching this id
      $(".btcs-single-slide-wrapper[data-slide-id='" + tabID + "']").addClass('active');
      $(this).addClass('active');
      if (e.originalEvent === undefined) {
        // code triggered click
      } else {
        // real click from user
        clearInterval(BTCSinterval); // stop the interval
        BTCSUserClicked = true;
      }
    }else{
      //If there has been a user click previously we need to prevent the code clicks from working
      if (e.originalEvent === undefined) {
        //This click is from code so ignor it
        return false;
      } else {
        //This click is from a user so do the things
        e.preventDefault();
        var tabID = $(this).attr('data-tab-id');
        // Remove active state from matching slide and tab nav items
        $(this).siblings('.tab-links').removeClass('active');
        $('.btcs-single-slide-wrapper').removeClass('active');
        // Add active class to slide and tab nav matching this id
        $(".btcs-single-slide-wrapper[data-slide-id='" + tabID + "']").addClass('active');
        $(this).addClass('active');
        clearInterval(BTCSinterval); // stop the interval
      }
    }
  });


  var allBTCSTabs = $('.btcs-tabs-wrapper .tab-links');

  //Looping through the tabs and clicking them programmatically
  function loopBTCSTabs(){
    var btcsTime = 0;
    allBTCSTabs.each(function(){
      var thisBtcs = $(this);
      setTimeout( function(){ 
        thisBtcs.trigger('click');
      }, btcsTime)
      btcsTime += 7000;
    });
  }

  loopBTCSTabs();
  var totalBTCSTime = 7000 * allBTCSTabs.length;
  var BTCSinterval = setInterval(loopBTCSTabs, totalBTCSTime)

}); /* end of as page load scripts */